.item {
    transition: 0.5s height;
    margin-top: 10px;
    border-radius: 7px;
    background: #e3e3e3;
    overflow: hidden;

    .development {
        border-radius: 7px;
        background: #e3e3e3; 
        padding: 10px;
    
        &.selected {
            background: #b0b9bf;
        }
    }
    
    .platformIconContainer {
        width: 50px;
        height: 50px; 
        margin-right: 10px;
    
        i {
            font-size: 48px;
        }
    }
    
    .detail {
        flex-grow: 1;
    
        .titleGroup {
            flex-grow: 1;
    
            .title {
                font-size: 1.2em;
            }
    
            .assignedTo {
                display: flex;
            }
        }
    
        .progressBarContainer {
            margin-top: 5px;
        }
    
        .updateDetail {
            margin-top: 10px;
        }
    }
    
    .itemInfo {
        height: 0px; 
        transition: 0.5s height;

        &.selected {
            height: 100px;
        }

        .spacer {
            margin: 10px;
            padding-left: 60px;
        }

        .developers {
            margin-left: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

:global {
    body.dark {
        :local {
            .item {
                background-color: #444;
    
                .development {
                    background-color: #444;
    
                    &.selected {
                        background-color: #666;
                    }
                }
            }
        }
    }
}