/* Fixes for layout in IE9 */
@media all and (min-width:0\0) and (min-resolution:.001dpcm) {
    :global {
        .ms-CommandBar-primaryCommands {
            display:inline-block;
        }
        .ms-CommandBar-sideCommands {
            display:block;
            float:right;
        }
        #app .ms-DetailsRow-fields {
            display:inline-block;
            position:relative;
            top:-1px;
        }
        #app .ms-Button-flexContainer {
            line-height:1.9em;
        }
        #app .ms-Button-textContainer {
            display:inline-block;
        }
        div[class*="ms-ContextualMenu is-open"] {
            -ms-overflow-y: hidden;
            overflow-y: hidden;
        }
        .ms-ChoiceField, .ms-ChoiceField[class*="ms-ChoiceField--icon"] {
            display: inline-block;
        }
        #app .ms-DetailsList div[class*="rootIsSelected_"] {
            background-color:rgb(0,120,215);
        }
        #app .ms-DetailsRow-check {
            text-align:center;
            padding-top:10px;
            .ms-Check {
                display:inline-block;
            }
        }
    }
}

:global {

    #app {
        margin:20px;
    }

}

