@media screen and (max-width: 430px) {
    .logo {
        width: 300px;
    }
}
@media screen and (max-width: 320px) {
    .logo {
        width: 250px;
    }
}
@media screen and (max-width: 290px) {
    .logo {
        width: 200px;
    }
}

.logoContainer {
    display: flex;

    .logo {
        margin: 9px 0px 9px 9px;
    }
}

.mainForm {
    flex-grow: 1;
    margin: 20px;
    user-select: none;
}

.menu {
    flex-grow: 1;
    border: 1px solid #333;
    border-radius: 7px;
    padding: 10px;
    align-items: center;

    > div {
        max-width: 350px;
    }
}

.graphContainer {
    > div {
        max-width: 750px;
        flex-grow: 1;

        @media screen and (max-width: 620px) {
            max-width: 500px;
        }
        @media screen and (max-width: 520px) {
            max-width: 400px;
        }
        @media screen and (max-width: 360px) {
            max-width: 250px;
        }

    }
    display: flex;
    justify-content: center;
    margin-right: 10px;
    background-color: #eee;
    padding: 10px;
    border-radius: 15px;
}

.dateControls {
    @media screen and (max-width: 620px) {
        padding: 10px;
    }

    @media screen and (max-width: 230px) {
        :global {
            .ms-Button-textContainer {
                display: none;
            }
        }
    }

    .datePicker {
        margin-bottom: 20px;
        margin-right: 20px;
        flex-grow: 9999;
        min-width: 160px;

        @media screen and (max-width: 620px) {
            margin-right: unset;
        }
    }
    .weekControls {
        flex-grow: 1;
        margin-right: 20px;
        margin-bottom: 20px;

        @media screen and (max-width: 510px) {
            flex-wrap: wrap;
            margin-right: unset;

            :global {
                .ms-Label {
                    flex-grow: 1; flex-shrink: 0;
                    flex-basis: 100%;
                }
            }
        }

        .weekControlBtns {
            @media screen and (max-width: 510px) {
                justify-content: space-between;
                flex-basis: 100%;
            }
        }
    }
}

:global {
    body.dark {
        :local {
            .logoContainer {
                background-color: #ccc;
            }
            .menu {
                border-color: white;
            }
            .graphContainer {
                background-color: #555;
            }
        }
    }
}