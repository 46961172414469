:global {
    @keyframes spin {
        from {
            rotate: 0deg;
        }
        to {
            rotate: 360deg;
        }
    }
}
.spin {
    :global {
        animation: .2s spin infinite;
    }
}

.saveDataInfo {
    align-self: center;
    margin: 0 10px;
    font-style: italic;
    color: grey;
}