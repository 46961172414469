.outlineDragArea {
    border: 2px dashed grey;

    &:hover {
        background-color: #ccccccaa;
    }

    &::after {
        content: 'Drag here';
        padding: 20px;
    }
}

.details {
    border: 1px solid #666;
    box-shadow: 2px 2px 2px #ccc;
    padding: 10px;
    position: relative;
    transition: box-shadow .2s ease-in-out, border .2s ease-in-out;

    &.hovered {
        box-shadow: 4px 4px 4px #bebee2;
        border: 2px solid #5757bb80;
    }

    &.dragging {
        background-color: #5757bb;
    }

    .devMenu {
        float: right;
    }

    .footer {

        :global {
            .ms-Stack {
                width: 100%;
            }
        }
    }

    .status {
        border-radius: 5px;
        padding: 1px 5px;
        border: 1px solid #ccc;
        margin-right: 5px; 
        margin-bottom: 5px;

        &[data-status="Pipeline"], &[data-status="Business Prioritised"] {
            background-color: #AFCBFF;
        }
        &[data-status="Review"] {
            background-color: #D5AAFF;
        }
        &[data-status="More Information Required"] {
            background-color: #FFBEBC;
        }
        &[data-status="Non-Prioritised"] {
            background-color: #BFFCC6;
        }
    }

    .matrixScore {
        margin-top: 5px;
        padding: 2px;
        border-radius: 2px;
    }
}

@keyframes pickingup {
    from { 
        left: -350px;
        opacity: 0;
    }
    to { 
        left: 0px;
        opacity: 0.5; 
    }
}

.dragClone {
    position: absolute;
    width: 0px;
    height: 0px;
    pointer-events: none;
    
    > div {
        width: 350px;
        height: 180px;
        overflow: hidden;
        position: relative;
        top: -75px;
        left: -175px;

        > div {
            display: flex;
            align-items: stretch;
            opacity: 0;
            width: 350px;
            height: 150px;
            position: relative;
            animation-fill-mode: forwards;
            animation-duration: 0.2s;
            animation-timing-function: ease-out;
            :local {
                animation-name: pickingup;
            }
        }
    }
    
    .details {
        width: 350px;
        height: 150px;
    }
}

.sortComponent {
    user-select: none;

    .development {
        flex-basis: 350px;
        min-height: 200px;
        display: flex;
        align-items: stretch;
        margin: 5px 5px 20px 5px;

        &.forTeam {
            min-height: 270px;
        }

        * {
            user-select: none;
        }

        .orderNo {
            flex: 0 0 20px;
            text-align: right;
        }
        
        .details {
            flex: 1;
            margin-left: 10px;
        }
    }
}

.priorityTable {
    border: 1px dashed grey;
    border-collapse: collapse;
    width: 100%;

    th {
        text-align: right;
    }
    td {
        text-align: left;
    }
    th, td {
        border: 1px dashed grey;
        padding: 1px 10px;
        width: 50%;
    }
}

:global {
    body.dark {
        :local {
            .details {
                background-color: #2a2a2a;
                border-color: black;
                box-shadow: 2px 2px 2px black;

                &.hovered {
                    box-shadow: 4px 4px 4px hsl(240, 38%, 22%);
                    border: 2px solid #5757bb80;
                }
            
                &.dragging {
                    background-color: #5757bb;
                }

                .status {
                    &[data-status="Pipeline"], &[data-status="Business Prioritised"] {
                        background-color: hsl(219, 20%, 34%);
                    }
                    &[data-status="Review"] {
                        background-color: hsl(270, 20%, 33%);
                    }
                    &[data-status="More Information Required"] {
                        background-color: hsl(2, 20%, 37%);
                    }
                    &[data-status="Non-Prioritised"] {
                        background-color: hsl(127, 20%, 37%);
                    }
                }
            }
        }
    }
}