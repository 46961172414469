.attachments {
    color: inherit;
    :global {
        .ms-CommandBar {
            background-color: #768692;

            .ms-OverflowSet-item {
                button {
                    background-color: #768692;
                }
            }
        }
    }
}

:global {
    body.dark {
        :local {
            .attachments {
                :global {
                    .ms-CommandBar {
                        background-color: #49535b;
                    }

                    .ms-OverflowSet-item {
                        button {
                            background-color: #49535b;
                        }
                    }
                }
            }
        }
    }
}