.formSection {
    margin-top: 15px;

    .sectionContainer {
        padding: 10px;
        border: 1px solid #bdbdbd;
        border-radius: 10px;

        &.shade {
            background-color: #ccc;
        }
    }
}

:global {
    body.dark {
        :local {
            .formSection {
                .sectionContainer {
                    &.shade {
                        background-color:#333;
                    }
                }
            }
        }
    }
}