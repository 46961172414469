@keyframes show {
    0% {
        opacity: 0;
        top: 10px;
        max-height: 0px;
    }
    10% {
        opacity: 1;
        top: 0px;
        max-height: 100px;
    }
    90% {
        opacity: 1;
        top: 0px;
        max-height: 100px;
    }
    100% {
        opacity: 0;
        top: -10px;
        max-height: 0px;
    }
}

.toastNotification {
    animation: show 5s;
    opacity: 0;
    overflow: hidden;
    max-width: 300px;
    position: relative;
    background: white;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 2px 2px 2px 2px #999;
}

:global {
    body.dark {
        :local {
            .toastNotification {
                background-color: #444;
                box-shadow: 2px 2px 2px 2px black;
            }
        }
    }
}