.icon i {
    font-size: 1.3em;
    margin-right:10px;
}
.icon {
    i.redIcon {
        color: darkred;
    }
}
.badge {
    min-width: 30px;
    height: 20px;
    margin-left: 10px;
    background: #b1324e;
    border-radius: 10px;
    line-height: 20px;
    color: white;
    text-align:center;
}
.dropdown {
    margin-bottom: 5px;
    padding: 10px;
    background: hsl(206deg, 11%, 82%);
    border-width: 1px 1px 1px 4px;
    border-color: #768692;
    border-style: solid;
    margin-top: 10px;
}
.pivot {
    :global {
        .ms-Pivot {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 5px;
            padding: 10px;
            background: hsl(206deg, 11%, 82%);
            border-width: 1px 1px 1px 4px;
            border-color: #768692;
            border-style: solid;
            margin-top: 10px;
        }
    }
}

:global {
    body.dark {
        :local {
            .dropdown {
                border-color: #3d505c;
                background-color: #29353d;
            }
            .pivot {
                :global {
                    .ms-Pivot {
                        border-color: #3d505c;
                        background-color: #29353d;
                    }
                }
            }
        }
    }
}