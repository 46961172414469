.stickyHeaderRow {
    :global {
        .ms-DetailsHeader {
            position: fixed;
            top: 52px;
            z-index: 9999;
            left: 0px;
            padding-left: 8px;
            transition: 1s left, 1s background-color;
            background-color: #dedede !important;
            font-weight: bold;
            box-shadow: 0px 1px 5px #929191;
        }
    }
    &.commandRoot {
        position: fixed;
        top: 0;
        z-index: 9999;
        left: 0px;
        width: 100%;
        background: white;
    }
}

.filter {
    display: flex;
}

/* row specific */
.even {
    background-color:#f7f7f7;
}
.odd {
    background-color:inherit;
}

.detailsListHeader {
    :global {
        .ms-DetailsHeader {
            background-color: #ebebeb;
            padding-top: 0px;
        }
    }
}

:global {
    body.dark {
        :local {
            .even {
                background-color:#1d1d1d;
            }
            .detailsListHeader {
                :global {
                    .ms-DetailsHeader {
                        background-color: black;
                    }
                    .ms-DetailsRow-cell {
                        color: white;
                    }
                }
            }
        }
    }
}