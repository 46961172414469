.headerMenu {
    :global {
        .ms-CommandBar {
            background-color: #768692;
        }
        .ms-CommandBar-secondaryCommand {
            .ms-OverflowSet-item {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                &:hover {
                    background-color: #425563;
                }
            }
        }
        .ms-CommandBar-primaryCommand {
            >.ms-OverflowSet-overflowButton>button {
                background-color: #768692;
                
                .ms-Button-menuIcon {
                    color: #E8EDEE;
                }
                
                &:hover {
                    background-color: #425563;
                    
                    .ms-Button-menuIcon {
                        color: white;
                    }
                    .ms-Button-menuIcon.is-expanded {
                        background-color: #425563;
                    }
                }
            }
            >.ms-OverflowSet-item>button {
                background-color: #768692;

                .ms-Button-label {
                    color: #E8EDEE;
                }
                .ms-Button-menuIcon {
                    color: #E8EDEE;
                }
                .ms-Button-icon {
                    color: "#E8EDEE";
                }
                .ms-Button-menuIcon.is-expanded {
                    background-color: #768692;
                }
                &:hover {
                    background-color: #425563;

                    .ms-Button-label {
                        color: white;
                    }
                    .ms-Button-menuIcon {
                        color: white;
                    }
                    .ms-Button-icon {
                        color: white;
                    }
                    .ms-Button-menuIcon.is-expanded {
                        background-color: #425563;
                    }
                }
            }
        }
    }
}

.darkModeTooltip {
    display: flex;
    align-items: center;
}
.darkModeToggle {
    margin-bottom: 0px;
    margin-right: 10px;
}

:global {
    body.dark {
        :local {
            .headerMenu {
                :global {
                    .ms-CommandBar {
                        background-color: #49535b;
                    }
                    .ms-CommandBar-secondaryCommand {
                        .ms-OverflowSet-item {
                            &:hover {
                                background-color: #2d3a43;
                            }
                        }
                    }
                    .ms-CommandBar-primaryCommand {
                        >.ms-OverflowSet-overflowButton>button {
                            background-color: #49535b;

                            &:hover {
                                background-color: #2d3a43;

                                .ms-Button-menuIcon.is-expanded {
                                    background-color: #2d3a43;
                                }
                            }
                        }
                        >.ms-OverflowSet-item>button {
                            background-color: #49535b;

                            &:hover {
                                background-color: #2d3a43;

                                .ms-Button-menuIcon.is-expanded {
                                    background-color: #2d3a43;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}