.entryCol {
    overflow: unset;
    text-overflow: unset;
    white-space: pre-wrap;
    color:black;
    font-size:1.2em;
}
.dateCol {
    overflow: unset;
    text-overflow: unset;
    white-space: normal;
}
.inlineSpinner {
    display: inline-block;
    margin-right: 10px;
}

:global {
    body.dark {
        :local {
            .entryCol {
                color: white;
            }
        }
    }
}