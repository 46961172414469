
@media screen and (max-width: 520px) {
    .logo {
        width: 200px;
    }
}
@media screen and (max-width: 320px) {
    .logo {
        width: 150px;
    }
}
.logoContainer {
    width: 100%; 
    justify-content: flex-end; 
    display: flex; 
    margin: 10px 0px;
}

.pivotMenu {
    :global {
        .ms-Pivot {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px 0px 10px 0px;
            background-color: hsl(206deg, 11%, 82%);
            padding: 10px;
            border-width: 1px 1px 1px 4px;
            border-style: solid;
            border-color: #768692;
            width: "100%";
        }
    }
}

.menuButton {
    height: unset;
    border-radius: 7px; 
    font-Size: 24px; 
    margin: 10px; 
    padding: 10px; 
    max-width: 80vw;
    box-shadow: 2px 2px 7px 2px #e3e3e3;
    top: 0px;
    transition: box-shadow 0.5s, top 0.2s;

    &:hover {
        top: -3px;
        box-shadow: 3px 3px 7px 2px #a3a3a3;
    }
    
    :global {
        .ms-Button-flexContainer { 
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.newAccountInfo {
    max-width: 500px;
    text-align: justify;
    padding: 20px;
    margin: 20px auto;
    border: 1px solid #eee;
    box-shadow: 2px 2px 4px 2px #999;
    border-radius: 10px 0px;
}

@keyframes wave {
    0% {
        transform: rotate(45deg)
    }
    50% {
        transform: rotate(-45deg)
    }
    100% {
        transform: rotate(44deg);
    }
}

.welcomeWaveIcon {
    width: 48px;
    height: 48px;
    font-size: 48px;
    transform-origin: 24px 24px;
    display: block;
    margin: 0 auto;
    animation: wave 1s infinite;
    animation-timing-function: none;
}

:global {
    body.dark {
        :local {
            .logoContainer {
                margin: 10px 10px 0px 10px;
                background-color: #ccc;

                img {
                    margin: 10px;
                }
            }
            .pivotMenu {
                :global {
                    .ms-Pivot {
                        border-color: #3d505c;
                        background-color: #29353d;
                    }
                }
            }
            .menuButton {
                transition: box-shadow 0.5s, top 0.2s, background-color 0.2s;
                background: linear-gradient(0deg, transparent 30%, #333 60%);
                box-shadow: 2px 2px 7px 2px #212121;
                border-color: #5a5a5a;

                &:hover {
                    background: linear-gradient(0deg, transparent 30%, #454545 60%);
                    box-shadow: 3px 3px 7px 2px black;
                }
            }
        }
    }
}