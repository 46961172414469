@media screen and (max-width: 430px) {
    .logo {
        width: 300px;
    }
}
@media screen and (max-width: 320px) {
    .logo {
        width: 250px;
    }
}
@media screen and (max-width: 290px) {
    .logo {
        width: 200px;
    }
}

.logoContainer {
    display: flex;

    .logo {
        margin: 9px 0px 9px 9px;
    }
}

:global {
    body.dark {
        :local {
            .logoContainer {
                background-color: #ccc;
            }
        }
    }
}