.developmentForm {
    color:inherit;

    .flagArea {
        float: right;
        margin-right: 20px;
        line-height:28px;

        i {
            font-size: 21px;
            margin-right: 10px;
            position: relative;
            top: 3px;
        }
    }

    :global {
        .expandingTextarea {
            width: 100%;
            textarea {
                width: 97%;
                padding: 7px;
                resize: vertical;
            }
        }

        .ms-Panel-header {
            margin: 10px 0 20px 0;
            padding: 0 15px 0 15px;
            
            .ms-Toggle {
                display: inline-block;
            }

            .ms-Panel-headerText {
                font-size: 21px;
                font-weight: 100;
                color: #323130;
                line-height: 32px;
                margin: 0;
            }
        }
    }
}

.tabs {
    > div[class*="ms-FocusZone"] {
        background-color: #e3e3e3;
        padding: 0px 10px 0px 10px;
    }
    :global {
        div[role="tabpanel"] {
            margin-top:20px;
        }
    }

    :global {
        .ms-Label {
            margin-top: 10px;
            font-weight:bold;
        }
    }
}

.completionSlider {
    :global {
        .ms-Label.ms-Slider-value {
            &:after {
                content: '%';
            }
        }
    }
}

.newForm {
    :global {
        .ms-Pivot {
            button[name="Updates"] {
                display:none;
            }
        }
    }
}