.upcomingDevTitle {
    margin-top: 0px;
}

.table {
    :global {
        .ms-DetailsRow-cell {
            white-space: normal;
        }
    }
}