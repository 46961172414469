:local(.ganttWrapper) > div:not(._3eULf) {
    @media screen and (max-width: 800px) {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    &::-webkit-scrollbar {
        width: 1.1rem;
        height: 1.1rem;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        background-clip: padding-box;
        border-radius: 10px;
        border: 6px solid transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.3);
    }
}
.ganttWrapper {
    @media screen and (max-width: 800px) {
        :global {
            ._3eULf {
                min-width: 800px;
            }
        }
    }
}

.tooltip {
    background: white;
    padding: 10px;
    border: 1px solid black;
    min-width: 300px;
}

.sortButton {
    padding: 15px;

    &.active {
        border-bottom: 2px solid rgb(58, 150, 221);
    }
}

:global {
    body.dark {
        :local(.ganttWrapper) > div:not(._3eULf) {
            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.2);
            }
        
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(255, 255, 255, 0.3);
            }
        }
        :local {
            .tooltip {
                background: black;
            }
            .ganttWrapper {
                :global {
                    ._3_ygE {
                        border-bottom: #191b1b 1px solid;
                        border-top: #191b1b 1px solid;
                        border-left: #191b1b 1px solid;
                    }
        
                    ._2eZzQ {
                        border-right: 1px solid #3b3b3b;
                    }
        
                    ._3ZbQT {
                        border-bottom: #191b1b 1px solid;
                        border-left: #191b1b 1px solid;
                    }
        
                    ._34SS0:nth-of-type(even) {
                        background-color: #0a0a0a;
                    }
        
                    ._2QjE6 {
                        color: #a9a9a9;
                    }
        
                    ._3T42e {
                        background: #000;
                        box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16), 0 3px 6px rgba(255, 255, 255, 0.23);
                    }
        
                    ._29NTg {
                        color: #999;
                    }
        
                    ._1eT-t::-webkit-scrollbar-thumb {
                        background: rgba(255, 255, 255, 0.2);
                    }
        
                    ._1eT-t::-webkit-scrollbar-thumb:hover {
                        background: rgba(255, 255, 255, 0.3);
                    }
        
                    ._2dZTy {
                        fill: #000;
                    }
        
                    ._2dZTy:nth-child(even) {
                        fill: #0a0a0a;
                    }
        
                    ._3rUKi {
                        stroke: #14100d;
                    }
        
                    ._RuwuK {
                        stroke: #191b1b;
                    }
        
                    ._9w8d5 {
                        fill: #ccc;
                    }
        
                    ._1rLuZ {
                        stroke: #191b1b;
                    }
        
                    ._2q1Kt {
                        fill: #aaa;
                    }
        
                    ._35nLX {
                        fill: #000;
                        stroke: #1f1f1f;
                    }
        
                    ._3w_5u {
                        fill: #222;
                    }
        
                    ._3zRJQ {
                        fill: #000;
                    }
        
                    ._3KcaM {
                        fill: #aaa;
                    }
        
                    ._2k9Ys::-webkit-scrollbar-thumb {
                        background: rgba(255, 255, 255, 0.2);
                    }
        
                    ._2k9Ys::-webkit-scrollbar-thumb:hover {
                        background: rgba(255, 255, 255, 0.3);
                    }
                }
            }
        }
    }
}
            