.platformMenu {

    position: relative;
    height: 50px;
    margin: 20px 0;

    .menuBtns {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
    }

    .chevron {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :global {
        .ms-Stack {
            padding: 10px;
            border-radius: 17px;
            background-color: #f1f1f1;
        }
        .ms-Icon {
            font-size: 32px;
        }
        .ms-Button--icon {
            background-color: #ccc;
            color: white;
            border-radius: 50%;
            padding: 25px;
        }
    }
    .allBtn {
        :global {
            .ms-Button--icon{
                background-color: #32b136 !important;
            }
        }
    } 
    .active {
        :global {
            .ms-Button--icon {
                background-color:#b1324e !important;
            }
        }
    }
}

@media screen and (max-width: 570px) {
    .menuBtns {
        :global {
            .ms-Button--icon {
                padding: 16px;  
            }
            .ms-Icon {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 280px) {
    .menuBtns {
        :global {
            .ms-Button--icon {
                padding: 2px;  
                width: 26px;
                height: 26px;
            }
            .ms-Icon {
                font-size: 14px;
                width: unset;
                height: unset;
            }
        }
    }
}


:global {
    body.dark {
        :local {
            .platformMenu {
                margin: 20px 0 30px 0;

                @media screen and (max-width: 570px) {
                    margin: 10px 0 15px 0;
                }

                :global {
                    .ms-Stack {
                        background-color: black;
                        margin-bottom: 0px;
                    }
                    .ms-Button--icon {
                        background-color: #666;
                    }
                }
            }
        }
    }
}