@media screen and (max-width: 430px) {
    .logo {
        width: 300px;
    }
}
@media screen and (max-width: 320px) {
    .logo {
        width: 250px;
    }
}
@media screen and (max-width: 290px) {
    .logo {
        width: 200px;
    }
}

.logoContainer {
    display: flex;

    .logo {
        margin: 9px 0px 9px 9px;
    }
}

// .developments {
//     :global {
//         .ms-DetailsList {
//             color: white;
//         }
//         .ms-DetailsRow:hover {
//             background-color: #2d2d2d;
//         }
//     }
// }

/* general */
.textCenter {
    text-align:center;
}
.textRight {
    text-align:right;
}

/* global */
:global {
    .ms-DetailsRow-cell {
        white-space: normal;
    }
    .ms-DetailsRow-fields:hover {
        .latestUpdate {
            cursor:pointer;
            color:#b1324e;
        }
    }
    .rag-green {
        color: darkgreen;
    }
    .rag-red {
        color: darkred;
    }
    .rag-amber {
        color: darkorange;
    }
    .ms-DetailsList > [role="grid"] > div[role="row"] {
        background-color:lightgrey;

        &:hover {
            [role="gridcell"]:first-of-type {
                visibility:hidden;
            }
        }
        [role="gridcell"]:nth-of-type(1) {
            visibility: hidden;
        }
    } 
    .stale-development .modifiedDate {
        background-color: #e3bfbf;
        font-weight:bold;
    }
    .review-date-warning .reviewDateCol {
        background-color: #efcda4;
        font-weight:bold;
    }
    .past-review-date .reviewDateCol {
        background-color: #e3bfbf;
        font-weight:bold;
    }
    /*.olitFooterRow {
        > .ms-DetailsRow-cell {
            display:none;
        }
    }*/
    .olitFooterRow, .deliveryFooterRow {
        > .ms-DetailsRow-fields {
            background-color: lightgrey;
        }
    }
}

/* header customisations */
.stickyHeaderRow {
    :global {
        .ms-DetailsHeader {
            position: fixed;
            top: 0;
            z-index: 9999;
            left: 0px;
            padding-left: 27px;
            transition: 1s left, 1s background-color;
            background-color: #dedede !important;
            font-weight: bold;
            box-shadow: 0px 1px 5px #929191;
        }
    }
}

/* column specific */
.platformIcon {
    font-size: 2.5em;
    color: #b1324e;
}
.developmentCol,
.updateCol,
.statusCol {
    white-space: normal !important;
    text-overflow: unset !important;
    padding: 3px 12px !important;
}
.rag {
    font-size:1.8em;
}

/* progress bar */
.barGrey {
    :global {
        .bar {
            background-color: #999;
            position: absolute;
            height: 17px;
            border-radius: 7px;
        }
    }
    background-color: #d3d3d3;
    width: 100%;
    height: 17px;
    border-radius: 7px;
    position: relative;
}
.barGreen {
    :global {
        .bar {
            background-color: #006747;
            position: absolute;
            height: 17px;
            border-radius: 7px;
        }
    }
    background-color: #7ab8a4;
    width: 100%;
    height: 17px;
    border-radius: 7px;
    position: relative;
}
.barDarkRed {
    :global {
        .bar {
            background-color: #5d0e26;
            position: absolute;
            height: 17px;
            border-radius: 7px;
        }
    }
    background-color: #a98e96;
    width: 100%;
    height: 17px;
    border-radius: 7px;
    position: relative;
}
.barRed {
    :global {
        .bar {
            background-color: #8A1538;
            position: absolute;
            height: 17px;
            border-radius: 7px;
        }
    }
    background-color: #a98e96;
    width: 100%;
    height: 17px;
    border-radius: 7px;
    position: relative;
}
.barAmber {
    :global {
        .bar {
            background-color: #d17a00;
            position: absolute;
            height: 17px;
            border-radius: 7px;
        }
    }
    background-color: #a99e8e;
    width: 100%;
    height: 17px;
    border-radius: 7px;
    position: relative;
}

/* info / filter area */
.infoBar {
    padding: 10px 20px;
    margin: 20px 0px;
    background-color: lightgrey;
    :global {
        .filterInfo {
            line-height: 2.3em;
        }
    }
}
.filterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    :global {
        .filter {
            display:inline-block;
            width:200px;
        }

        div:nth-of-type(2) {
            margin-left: 10px;
        }
    }
}
.keyboardInfo {
    text-align:right;
    i {
        font-size:3em;
        color:#999;
    }
    :global {
        .ms-TooltipHost {
            width:40px;
            height:40px;
            display:inline-block;
        }
    }
}

/* dialogs */
.deletionDialog,
.statusDialog {
    :global {
        .ms-Dialog-main {
            min-width: 600px;
        }
    }
}
.statusDialog {
    :global {
        .newStatus {
            font-size: 1.4em;
            margin-left: 20px;
        }
        .ms-MessageBar-icon {
            display:none;
        }
        .ms-MessageBar-innerText {
            padding-left:10px;
        }
    } 
}
.statusPickerDialog {
    :global {
        .ms-Dialog-main {
            min-width:600px;
        }
        .ms-Dialog-content {
            button {
                display:inline-block;
                &.ms-Button--default {
                    min-width:250px;
                    background-color:#b1324e;
                    color:white !important;
                }
                margin:10px;
                padding:5px;
            }
            i, button {
                vertical-align:middle;
            }
        }
    }
}

// Dark mode

:global {
    body.dark {
        :local {
            .logoContainer {
                background-color: #ccc;
            }
        }
        .ms-DetailsRow-fields:hover {
            .latestUpdate {
                color: lightblue;
            }
        }
        .ms-DetailsList > [role="grid"] > div[role="row"] {
            background-color: #333;
        }
    }
}