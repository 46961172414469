.label {
    margin-right: 5px;
}

.filter {
    display:inline-block;
    width:200px;
}

.filterContainer {
    display: flex; 
    align-items: center;
}

.resultCount {
    margin-left: 5px;
}