.dayTimeSelect {
    display: grid; 
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 22px 1fr 1fr; 
    gap: 0;
    height: 130px;
    margin-top: 20px;

    > div {
        border: 1px solid #eee;
    }

    .header {
        text-align: center;
    }
    .option {
        &:hover {
            background-color: #eee;
        }
        &.selected {
            background-color: green;
        }
    }
}

.am, .pm {
    display: flex;
    align-items: center;
    justify-content: center;
}

.am {
    grid-row: 2; grid-column: 1; 
}
.pm {
    grid-row: 3; grid-column: 1; 
}

:global {
    body.dark {
        :local {
            .dayTimeSelect {
                .option {
                    &:hover {
                        background-color: #6e6e6e;
                    }
                }
            }
        }
    }
}