@import "../../node_modules/office-ui-fabric-react/dist/sass/_References";

.questionsContainer {
    position: relative;
    display: flex;
    transition: .5s ease-in-out left;
    overscroll-behavior-x: none;
}

.buttonRow {
    flex-shrink: 0;
    :global .ms-Stack-inner {
        @media screen and (max-width: 780px) {
            flex-direction:column;
        }
    }
}
.button {
    flex-basis: calc(25% - 90px);
    margin: 15px;
    padding: 5px 30px;
    min-height: 150px;
    color: white;
    border-radius: 7px;
    box-shadow: $ms-depth-shadow-16;

    background-color: $ms-color-sharedCyan40;

    &:hover {
        background-color: $ms-color-sharedCyan30;
    }

    &.isSelected {
        color: black;
        background-color: $ms-color-sharedCyan10;
    }
}

@media screen and (max-width: 520px) {
    .logo {
        width: 200px;
    }
}
@media screen and (max-width: 320px) {
    .logo {
        width: 150px;
    }
}
.logoContainer {
    width: 100%; 
    justify-content: flex-end; 
    display: flex; 
    margin: 10px 0px;
}