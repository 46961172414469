

.calendarItemPicker {
    .day {
        padding: 5px 10px;
        box-shadow: 0px 0px 0px 1px #eee;
        &:first-of-type {
            border-radius: 10px 10px 0px 0px;
        }
        &:last-of-type {
            border-radius: 0px 0px 10px 10px;
        }
        &.odd {
            background-color: #f5f5f5;
        }
    }
}

.calendarItem {
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: #eee;
    }
    &.isSelected {
        background-color: #005EB8;
        color: white;
    }
    &.disabled {
        cursor: not-allowed;
        color: grey;
    }
}

.dnaBadge {
    width: 70px;
    padding: 2px 5px;
    border-radius: 6px;
    background: grey;
    font-size: 10px;
    text-align: center;
    color: white;
}

:global {
    body.dark {
        :local {
            .calendarItem {
                &:hover {
                    background-color: #666;
                }
            }
            .calendarItemPicker {
                .day {
                    box-shadow: 0px 0px 0px 1px #6e6e6e;
                    &.odd {
                        background-color: #333;
                    }
                }
            }
        }
    }
}