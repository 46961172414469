.developmentsList {
    border: 1px solid #eee;
    border-radius: 7px;

    &.showClosed {
        .development.closed {
            height: 22px;
            padding: 10px;
            opacity: 1;
            border-width: 1px;

            &:not(.first-of-type) {
                border-top: 1px solid #eee;
            }

            &.ns {
                height: 0px;
                opacity: 0;
                padding: 0px;
                border-width: 0px;
            }
        }
    }

    .development {
        padding: 10px;
        height: 22px;
        opacity: 1;
        transition: height 0.5s, opacity 0.5s, padding 0.5s, border-width 0.5s;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        
        &:hover {
            background-color: #eee;

            &.s {
                background-color: #004585 !important;
            }
        }

        &:not(.first-of-type) {
            &:not(.closed) {
                border-top: 1px solid #eee;
            }
        }

        &.ns, &.closed {
            height: 0px;
            opacity: 0;
            padding: 0px;
            border-width: 0px;
        }

        &.s {
            background-color: #005eb8;
            color: white;
        }
    }
}

:global {
    body.dark {
        :local {
            .developmentsList {
                border: 1px solid #6e6e6e;

                &.showClosed {
                    .development.closed {
                        &:not(.first-of-type) {
                            border-top: 1px solid #6e6e6e;
                        }
                    }
                }
                .development {
                    &:hover {
                        background-color: #666;
                    }
                    &:not(.first-of-type) {
                        &:not(.closed) {
                            border-top: 1px solid #6e6e6e;
                        }
                    }
                }
            }
        }
    }
}