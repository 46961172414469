@media screen and (max-width: 430px) {
    .logo {
        width: 300px;
    }
}
@media screen and (max-width: 320px) {
    .logo {
        width: 250px;
    }
}
@media screen and (max-width: 290px) {
    .logo {
        width: 200px;
    }
}

.logoContainer {
    display: flex;

    .logo {
        margin: 9px 0px 9px 9px;
    }
}

.userSearchContainer {
    padding: 10px;
    background: hsl(206deg, 11%, 82%);
    border-width: 1px 1px 1px 4px;
    border-color: #768692;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 5px;
}


.selectedUserDetails {
    padding: 20px;
    
    > div {
        box-shadow: #9e9e9e 0px 1px 17px;
        padding: 20px;
        &:first-of-type {
            align-self: flex-start; 
            max-width: calc(100% - 80px);
        }
        &:last-of-type {
            flex-grow: 1;
            margin-left: 20px;
        }
    }

    .dropdown {
        max-width: 290px;
    }
}
@media screen and (max-width: 900px) {
    .selectedUserDetails {
        flex-wrap: wrap;
        > div:last-of-type {
            margin-top: 20px;
            margin-left: unset;
        }
    }
}
@media screen and (max-width: 320px) {
    .selectedUserDetails {
        .dropdown {
            max-width: 150px;
        }
    }
}

:global {
    body.dark {
        :local {
            .logoContainer {
                background-color: #ccc;
            }
            .userSearchContainer {
                border-color: #3d505c;
                background-color: #29353d;
            }
            .selectedUserDetails {
                > div {
                    box-shadow: black 0px 1px 17px;
                }
            }
        }
    }
}